import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module'; 
import { AppComponent } from './app.component';
import { AboutComponent } from './About/about.component';
import { NewsroomComponent } from './Newsroom/newsroom.component';
import { ResourcesComponent } from './Resources/resources.component';
import { FaqComponent } from './Faq/Faq.component';
import { HomeComponent } from './Home/home.component';
import { HeaderComponent } from './Header/header.component';
import { FooterComponent } from './Footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    NewsroomComponent,
    ResourcesComponent,
    FaqComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [BrowserModule, NgbModule,AppRoutingModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
