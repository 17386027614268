<div class="container container-fluid headersection">
    <div class="row headercont">
  
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 alertmsg ">
            <p class="covidalertmsg"><strong>For specific COVID-19 health updates and safety information,</strong><a href="https://service.govdelivery.com/accounts/NESTATE/subscriber/new?qsp=2931" id="alertlink" ><strong style="color:#0886B1;"><u>Click here</u></strong></a></p>
         </div>
        
        
               <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 branding">
                <img id="headericon" src="assets/Bitmap Copy 2.png" alt="Offical Nebraska Government Website"><br>
                <img id="headerlogo" src="https://www.nebraska.gov/img/nebraska-logo.png" alt="Nebraska.gov Logo">
              </div>  -->
  
                <!-- <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 left-header">
  
                  <img id="headerlogo" src="https://www.nebraska.gov/img/nebraska-logo.png" alt="Nebraska.gov Logo">
                </div> -->
       
               <!-- navbar -->
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12  navright-header">
               <nav class="navbar navbar-expand-lg ">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 right-header">
              <img id="headericon" src="assets/Bitmap Copy 2.png" alt="Offical Nebraska Government Website">
             <img id="headerlogo" src="https://www.nebraska.gov/img/nebraska-logo.png" alt="Nebraska.gov Logo">
            </div>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
               <span class="navbar-toggler-icon"></span>
              </button>
      
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-8 ">
            <div class="collapse navbar-collapse navbarheader" id="navbarNav">
             <ul class="navbar-nav">
              <li class="nav-item ">
                <a class="nav-link" click="" href="/About">ABOUT COVID-19<span class="sr-only">(current)</span></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" click="" href="/Resources">CORONAVIRUS RELIEF FUND</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/Newsroom">NEWSROOM</a>
              </li>
               <!-- <li class="nav-item">
                <a class="nav-link" href="/Faq">FAQ</a>
              </li>  -->
            </ul>
          </div>
        </div>
        </nav>
        
       </div>
      </div> 
    </div>