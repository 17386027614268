 <!-- footer -->
  
 <div class="footer container container-fluid">
    <div class="row">
        <div class="col-sm-8 col-xs-12 col-lg-8 left-footer">
              <div class="region region-footer-left">
     <section id="block-block-12" class="block block-block clearfix">
      <img id="footericon" src="assets/Bitmap Copy 2.png" alt="Offical Nebraska Government Website"><br>
      <img id="footerlogo" src="https://www.nebraska.gov/img/nebraska-logo.png" alt="Nebraska.gov Logo">
      <!-- <img id="footerlogo" src="https://www.nebraska.gov/img/nebraska-logo.png" alt="Nebraska.gov Logo"><br><br> -->
       <p class="boxpo">P.O. Box 3395 Omaha, NE 68103 USA</p>
      <p class="rtecenter">© 2020 State of Nebraska</p>
  
    </section>
      </div>
        </div>
        <!-- <div class="col-sm-4 col-xs-12">
              <div class="region region-footer-middle">
     <section id="block-block-13" class="block block-block clearfix">
  
  
    <p class="rtecenter"><a href="http://www.nebraska.gov/" target="_blank" rel="noopener noreferrer">Nebraska.gov</a>&nbsp; |&nbsp; <a href="http://www.nebraska.gov/policies.html" target="_blank" rel="noopener noreferrer">Policies</a>&nbsp; |&nbsp; <a href="../user">Login</a></p>
  
    </section>
   </div>
        </div> -->
        <div class="col-sm-4 col-xs-12 right-footer">
              <div class="region region-footer-right ">
  <section id="block-block-11" class="block block-block clearfix">
  
    <p class="socialmediaicons">
      <a href="https://www.facebook.com/GovernorPeteRicketts" ><img src="assets/usa-social-link-fb.png" alt="facebookredirect"></a>&nbsp;&nbsp;
      <a href="https://twitter.com/GovRicketts" ><img src="assets/usa-social-link-twitter.png"  alt="facebookredirect"></a>&nbsp;&nbsp;
      <a href="https://www.youtube.com/channel/UCcDw3_CGhTVcVtxoGKRkjlQ/feed" ><img src="assets/usa-social-link-youtube.png"  alt="facebookredirect"></a>&nbsp;&nbsp;
      <a href="https://ne-test-governor.cdc.nicusa.com/governor-news" ><img src="assets/usa-social-link-rssfeed.png"  alt="facebookredirect"></a>&nbsp;&nbsp;
 
    </p>
    <p class="footertext">Got questions? Call NE Covid19<br>
      Hotline 800.919.7886</p>
      <img class="footer-bg" src="assets/State Seal.png" style="width: 100%;">
     
  
   </section>
     </div>
        </div>
         </div>
    
        </div>