import {Component} from '@angular/core';

@Component({

    selector: 'app-resources',
    templateUrl:'./resources.component.html',
})

export class ResourcesComponent{


}