import { Component } from '@angular/core';

@Component({
  selector: 'app-newsroom',
  templateUrl: './newsroom.component.html',
  styleUrls: ['./newsroom.component.css'],
})
export class NewsroomComponent {
  title = 'newsroom';
  name = 'Newsroom';
  page = 1;
  pageSize = 10;
  items = [];
  constructor() {
    this.items = Array(150)
      .fill(0)
      .map((x, i) => ({
        url: 'https://via.placeholder.com/600/1ee8a4',
        subject: 'Article title ' + i,
        content:
          'Donec id elit non mi porta gravida at eget metus. Etiam porta sem malesuada magna mollis euismod. Donec sed odio dui. ' +
          i,
        date: new Date().toISOString(),
      }));
  }
}
