



<div class="container ">
    <div class="row">
      <div class="govnr-msg col-xs-12 col-md-8 col-lg-6 offset-lg-2">
      
        <p style="color:black;text-align: justify;">


            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        </p>
      
    </div>
    </div>
  </div>



<div class="container">
    <div class="row">
       <div class="col-xs-12 col-md-8 col-lg-8 clear-fix ">

        <!-- <img id="statsimg" src="/assets/nebraska_stats.png" style="width:800px;height:500px;" alt="Nebraska.gov Logo"><br><br> -->

       </div>
    
    </div>
    <div class="col-xs-12 col-md-8 col-lg-4 ">
       <div style="color:black;">
         <div class="twitter-timeline twitter-timeline-rendered">
          <h5> Connect With Us</h5><i class="fa fa-twitter" aria-hidden="true"></i>
          <p><a class="twitter-timeline" href="https://twitter.com/GovRicketts" data-width="300"
            data-height="300"
            data-chrome="nofooter noborders" data-tweetlimit="1">
         
          </a></p>
          
    </div>
    </div>
  
</div>
 
<!-- cards
<div class="container-fluid" >
  <div class="row">
    <div class="col-xs-12 col-md-12 col-lg-12">
     <img id="governorimg" src="assets/governor.jpg" alt="Offical Nebraska Government Website"> -->
     <!-- </div>
    <div class="row">
    <div class="card col-xs-12 col-md-4 col-lg-5">
      <img class="card-img-top" src="..." alt="Card image cap">
      <div class="card-body">
        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
      </div>
      </div>
    </div>
      <div class="row">
        <div class="card col-xs-12 col-md-4 col-lg-5">
          <img class="card-img-top" src="..." alt="Card image cap">
          <div class="card-body">
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          </div>
          </div>
      </div>
          <div class="row">
            <div class="card col-xs-12 col-md-4 col-lg-5">
              <img class="card-img-top" src="..." alt="Card image cap">
              <div class="card-body">
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              </div>
              </div>
            
    
    
  </div>
  </div> 
</div> --> 