import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutComponent } from './About/about.component';
import { NewsroomComponent } from './Newsroom/newsroom.component';
import { ResourcesComponent } from './Resources/resources.component';
import { FaqComponent } from './Faq/Faq.component';
import { HomeComponent } from './Home/home.component';
import { HeaderComponent } from './Header/header.component';
import { FooterComponent } from './Footer/footer.component';

const appRoutes: Routes = [
  { path: '', redirectTo: '/Home', pathMatch: 'full' }, 
  { path: 'About', component: AboutComponent },
  { path: 'Newsroom', component: NewsroomComponent },
  { path: 'Faq', component: FaqComponent },
  { path: 'Resources', component: ResourcesComponent },
  { path: 'Home', component: HomeComponent },
  { path: 'Footer', component: FooterComponent }
];

@NgModule({
  imports: [RouterModule.forRoot( appRoutes, { enableTracing: true })],
  exports: [RouterModule]
})

export class AppRoutingModule {}
