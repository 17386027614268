<div style="margin: 20px;width: 100%;">
    <h1>Newsroom</h1>
    <div  style="display: inline-block;">
    <table width="500px">
      <tr style="border: solid thin;"
        *ngFor="
          let item of items
            | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize
        "
      >
        <td>
        <div class="paragraphs">
          <div class="row">
            <div class="span1">
              <img style="float:left" src="{{ item.url }}" alt="" width="50" height="50"/>
              <div class="content-heading"><p><a href="">{{ item.subject }}</a></p><p> {{ item.date | date }}</p></div>
              <p style="clear:both">{{ item.content }}</p>
            </div>
          </div>
        </div>
        </td>
      </tr>
    </table>
    <ngb-pagination
      class="d-flex justiy-content-center"
      [collectionSize]="items.length"
      [(page)]="page"
      [maxSize]="3"
      [rotate]="false"
      [ellipses]="false"
      [boundaryLinks]="false"
    ></ngb-pagination>
  </div>
  <!--div style="display: inline-block;">
    <p>test nebraska</p>
  </div-->
  </div>
  